import {
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-premium";

import { TextField } from "@mui/material";
import { useRef } from "react";

export const VERY_SMALL_WIDTH = 75;
export const SMALL_WIDTH = 120;
export const MEDIUM_WIDTH = 140;
export const LARGE_WIDTH = 160;
export const EXTRA_LARGE_WIDTH = 180;
export const EXTRA_EXTRA_LARGE_WIDTH = 300;

export function StringInputComponent(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
  const textRef: React.Ref<any> = useRef(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    applyValue({ ...item, value: event.target.value } as GridFilterItem);
  };

  return (
    <TextField
      id="outlined-value"
      label="Filter value"
      placeholder="Filter value"
      variant="standard"
      onChange={handleChange}
      ref={textRef}
      value={item.value}
    />
  );
}

export const withStringOperators: GridFilterOperator<
  any,
  number | string | null,
  any
>[] = [
  ...getGridStringOperators(),
  {
    label: "does not contain",
    value: "does not contain",
    getApplyFilterFn: (
      filterItem: GridFilterItem,
      column: GridColDef<any, string | number | null, any>
    ) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (value, row, colDef, apiRef): boolean => {
        return (
          typeof value === "string" &&
          !value.toLowerCase().includes(filterItem.value.toLowerCase())
        );
      };
    },
    InputComponent: StringInputComponent,
  },
];

export const withoutStringOperators: GridFilterOperator<
  any,
  number | string | null,
  any
>[] = getGridNumericOperators().filter(
  (operator) =>
    operator.value === ">" ||
    operator.value === "<" ||
    operator.value === "=" ||
    operator.value === "!=" ||
    operator.value === "<=" ||
    operator.value === ">="
);
