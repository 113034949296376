import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  Tooltip,
} from "chart.js";
import {
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { APPBAR_HEIGHT_AS_NUM } from "../../app_bar/Toolbar";
import LeagueTableDashboard from "../../components/dashboards/LeagueTableDashboard";
import annotationPlugin from "chartjs-plugin-annotation";
import { useLeagueTable } from "../../api/queries";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(ArcElement, Tooltip, Legend, annotationPlugin, zoomPlugin);

const OperationsPage = () => {
  // CONTEXT
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { height } = useWindowSizeContext();

  // QUERIES
  const leagueTableData = useLeagueTable().data;

//   const divider = <Grid item xs={12} pt={4} pb={4}>
//                     <Divider
//                         sx={{
//                           borderBottomWidth: 1,
//                           borderBottomColor: theme.palette.primary.main,
//                         }}
//                     />
//                   </Grid>

  return (
    <Grid
      container
      sx={{ maxHeight: height - APPBAR_HEIGHT_AS_NUM, overflow: "auto", padding: isScreenSmall ? 0 : 8 }}
    >
        {/* {team === FIRST_TEAM && 
          <>
            <Grid item sm={12} sx={{width: "100%", paddingTop: isScreenSmall ? 4 : 0 }}>
              <Rankings data={operationsData?.rank_data} /> 
            </Grid>

            {divider}
          </>
        } */}

        <Grid item md={12}>
          {leagueTableData && <LeagueTableDashboard tableEntries={leagueTableData} />}
        </Grid>

        {/* {club === CHICAGO_FIRE &&
            <>
                {divider}

                <Grid 
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >

                <Grid 
                  item 
                  xs={12}
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Box alignContent='center' textAlign='center'>
                    <Typography
                        variant="h5"
                        sx={{ fontWeight: 400, textDecoration: "underline", marginBottom: 2 }}
                    >
                        SALARY INFO
                    </Typography>

                    <Iframe 
                        url={SALARY_VISUAL_URL}
                        frameBorder={0}
                        width={`${width*.85}px`}
                        height={`${height*.85}px`}
                        id=""
                        className=""
                        display="block"
                        position="relative"
                    />
                  </Box>
                </Grid>
              </Grid>
            </>
        } */}
    </Grid>
  );
};

export default OperationsPage;
