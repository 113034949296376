import { Badge, Stack } from "@mui/material";
import { Query, useIsFetching, useQueryClient } from "react-query";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountMenu from "./AccountMenu";
import Box from "@mui/material/Box";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import ClubMenu from "./ClubMenu";
import IconButton from "@mui/material/IconButton";
import NotificationsMenu from "./NotificationsMenu";
import Tooltip from "@mui/material/Tooltip";
import { useClubContext } from "../contexts/ClubContext";
import { useNotificationsContext } from "../contexts/NotificationContext";
import { useState } from "react";

export default function ToolbarRight() {
  // CONTEXT
  const { logo } = useClubContext();
  const { notifications } = useNotificationsContext();
  const isFetching = useIsFetching();
  const queryClient = useQueryClient();

  // ANCHORS
  const [notificationsMenuAnchorEl, setNotificationsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [clubMenuAnchorEl, setClubMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const notificationsMenuOpen = Boolean(notificationsMenuAnchorEl);
  const accountMenuOpen = Boolean(accountMenuAnchorEl);
  const clubMenuOpen = Boolean(clubMenuAnchorEl);

  const handleAccountClick = (event: React.MouseEvent<HTMLElement>) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleNotificationsClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationsMenuAnchorEl(event.currentTarget);
  };

  const handleClubMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setClubMenuAnchorEl(event.currentTarget);
  };

  let accountToolTipContent;
  if (isFetching) {
    accountToolTipContent = (
        <>
        fetching data ({isFetching} requests remaining)
            <ul>
                {queryClient.getQueryCache().getAll().map((query: Query) => {
                    if (query.isFetching()) {
                        const key = `${query.queryKey[0]}`;
                        return <li key={key}>{key}</li>;
                    } else {
                        return "";
                    }
                })}
            </ul>
        </>
    );
  } else {
    accountToolTipContent = "Switch Club";
  }

  return (
    <Box sx={{ marginLeft: "auto" }}>
      <Stack direction="row" pl={0} pr={0}>
        <Tooltip title="Notifications">
          <IconButton
            id="long-button"
            aria-label="more"
            aria-controls={notificationsMenuOpen ? "long-menu" : undefined}
            aria-expanded={notificationsMenuOpen ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleNotificationsClick}
          >
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={notifications?.length || 0}
              color="secondary"
            >
              <CircleNotificationsIcon color="info" />
            </Badge>
          </IconButton>
        </Tooltip>

        <Tooltip title="Account">
          <IconButton
            color="info"
            aria-controls={accountMenuOpen ? "account-menu" : undefined}
            aria-expanded={accountMenuOpen ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleAccountClick}
            size="small"
          >
            <AccountCircleIcon />
          </IconButton>
        </Tooltip>

        <Tooltip 
            title={accountToolTipContent}
        >
            <IconButton
                color="info"
                aria-controls={clubMenuOpen ? "club-menu" : undefined}
                aria-expanded={clubMenuOpen ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClubMenuClick}
                size="small"
                sx={{ ml: 0.5 }}
                edge="end"
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={isFetching}
                color="secondary"
              >
                {logo}
              </Badge>
            </IconButton>
        </Tooltip>
      </Stack>

      {/* NOTIFICATIONS MENU */}
      <NotificationsMenu
        notificationsMenuOpen={notificationsMenuOpen}
        notificationsMenuAnchorEl={notificationsMenuAnchorEl}
        setNotificationsMenuAnchorEl={setNotificationsMenuAnchorEl}
      />

      {/* ACCOUNT MENU */}
      <AccountMenu
        accountMenuOpen={accountMenuOpen}
        accountMenuAnchorEl={accountMenuAnchorEl}
        setAccountMenuAnchorEl={setAccountMenuAnchorEl}
      />

      {/* CLUB MENU */}
      <ClubMenu
        clubMenuOpen={clubMenuOpen}
        clubMenuAnchorEl={clubMenuAnchorEl}
        setClubMenuAnchorEl={setClubMenuAnchorEl}
      />
    </Box>
  );
}
