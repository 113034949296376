import { Avatar, Typography } from "@mui/material";
import {
  EMPTY_FIELD,
  SECOND_TEAM,
} from "../constants";
import {
  EXTRA_EXTRA_LARGE_WIDTH,
  EXTRA_LARGE_WIDTH,
  LARGE_WIDTH,
  MEDIUM_WIDTH,
  SMALL_WIDTH,
  VERY_SMALL_WIDTH,
  withStringOperators,
  withoutStringOperators,
} from "../utils/dataGridHelpers";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid-premium";
import {
  formatDateToLocal,
  formatDateToLocalWithoutTimeYearFirst,
} from "../utils/dates";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { User } from "../api/types";
import moment from "moment";
import { useConstantsContext } from "../contexts/ConstantsContext";
import { useTeamContext } from "../contexts/TeamContext";
import { useThemeContext } from "../contexts/CustomThemeContext";

export const GROUPING_COL = {
  field: "grouping",
  headerName: "GROUP",
  minWidth: EXTRA_EXTRA_LARGE_WIDTH,
  aggregable: true,
  filterOperators: withStringOperators,
  leafField: "player_name",
  type: "string"
} as GridColDef;

export const ScoutedPlayersDataGridCols = (): GridColDef[] => {
  const { team } = useTeamContext();

  let columns = [
    // {
    //   field: "rating",
    //   headerName: "RATING",
    //   minWidth: LARGE_WIDTH,
    //   width: LARGE_WIDTH,
    //   aggregable: true,
    //   type: "number",
    //   filterOperators: withoutStringOperators,
    //   valueFormatter: ratingFormatter,
    // },

    {
      field: "date",
      headerName: "DATE",
      minWidth: MEDIUM_WIDTH,
      type: "date",
      aggregable: true,
      valueFormatter: (value: any, row: any, column: any, apiRef: any) => {
        return value
          ? formatDateToLocalWithoutTimeYearFirst(value)
          : null;
      },
      valueGetter: (value: any, row: any, column: any, apiRef: any) => {
        return value ? new Date(value) : null;
      },
    } as GridColDef,
    GROUPING_COL,
    {
      field: "player_name",
      headerName: "PLAYER",
      minWidth: EXTRA_LARGE_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
      leafField: "club",
      valueGetter: (value: any, row: any, column: any, apiRef: any) => {
        return row["last_name"]
          ? `${row["first_name"]} ${row["last_name"]}`
          : null;
      },
      groupingValueGetter: (row) => {
        return row["last_name"]
          ? `${row["first_name"]} ${row["last_name"]}`
          : null;
      },
      renderCell: (params) => {
      // console.debug(params);
      return (
          <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Avatar
              src={params.row.image}
              sx={{ width: 32, height: 32, display: 'flex', marginRight: 1 }}
            />
            {params.value}
          </div>
        );
      },
    } as GridColDef,
    {
      field: "first_name",
      headerName: "FIRST NAME",
      minWidth: MEDIUM_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
      type: "string"
    } as GridColDef,
    {
      field: "last_name",
      headerName: "LAST NAME",
      minWidth: MEDIUM_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
    } as GridColDef,
    {
      field: "position",
      headerName: "POSITION",
      minWidth: EXTRA_LARGE_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
    } as GridColDef,
    {
      field: "team",
      headerName: "TEAM",
      minWidth: EXTRA_LARGE_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
    } as GridColDef,
    // {
    //   field: "position",
    //   headerName: "POSITION",
    //   minWidth: EXTRA_LARGE_WIDTH,
    //   width: EXTRA_LARGE_WIDTH,
    //   aggregable: true,
    //   filterOperators: withStringOperators,
    //   valueFormatter: (params: GridValueFormatterParams<number>) => {
    //     return SCOUTING_POSITIONS[params.value - 1];
    //   },
    // },
    {
      field: "domestic",
      headerName: "DOMESTIC",
      minWidth: MEDIUM_WIDTH,
      type: "boolean",
    } as GridColDef,
    // {
    //   field: "secondary_position",
    //   headerName: "SECONDARY POSITION",
    //   minWidth: EXTRA_LARGE_WIDTH,
    //   width: EXTRA_LARGE_WIDTH,
    //   aggregable: true,
    //   filterOperators: withStringOperators,
    //   valueFormatter: (params: GridValueFormatterParams<number>) => {
    //     return SCOUTING_POSITIONS[params.value - 1];
    //   },
    // },
    {
      field: "birthdate",
      headerName: "BIRTHDATE",
      minWidth: MEDIUM_WIDTH,
      type: "date",
      aggregable: true,
      filterOperators: withoutStringOperators,
      valueGetter: (value: any, row: any, column: any, apiRef: any) => {
        return value ? new Date(value) : "";
      },
      valueFormatter: (value: any, row: any, column: any, apiRef: any) => {
        return value
          ? formatDateToLocalWithoutTimeYearFirst(value)
          : "";
      },
    } as GridColDef,
    {
      field: "birth_year",
      headerName: "BIRTH YEAR",
      minWidth: SMALL_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
    } as GridColDef,
    {
      field: "kit_number",
      headerName: "KIT #",
      minWidth: VERY_SMALL_WIDTH,
      width: VERY_SMALL_WIDTH,
      aggregable: true,
      type: "number",
      filterOperators: withoutStringOperators,
    } as GridColDef,
    {
      field: "location",
      headerName: "LOCATION",
      minWidth: SMALL_WIDTH,
      type: "string",
      aggregable: true,
      filterOperators: withStringOperators,
    } as GridColDef,
    {
      field: "contact_info",
      headerName: "CONTACT",
      minWidth: LARGE_WIDTH,
      type: "string",
      aggregable: true,
      filterOperators: withStringOperators,
    } as GridColDef,
    {
      field: "other_contact_info",
      headerName: "CONTACT (OTHER)",
      minWidth: LARGE_WIDTH,
      type: "string",
      aggregable: true,
      filterOperators: withStringOperators,
    } as GridColDef,
    {
      field: "foot",
      headerName: "FOOT",
      minWidth: VERY_SMALL_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
    } as GridColDef
    // {
    //   field: "scout",
    //   headerName: "SCOUT",
    //   minWidth: LARGE_WIDTH,
    //   aggregable: true,
    //   filterOperators: withStringOperators,
    // } as GridColDef,
  ];

  if (team === SECOND_TEAM) {
    columns = columns.concat([
      {
        field: "year_in_school",
        headerName: "YR IN SCHOOL",
        minWidth: MEDIUM_WIDTH,
        aggregable: true,
        filterOperators: withStringOperators,
        type: "string"
      } as GridColDef,
    ]);
  }

  return columns;
};

export const SchedulingDataGridCols = (
  handleEditGame: (id: number) => void,
  handleDeleteGame: (id: number) => void,
  handleTeamClick: (team: string) => void
): GridColDef[] => {
  const { users } = useConstantsContext();
  const { theme } = useThemeContext();

  return [
    {
      field: "date_time",
      headerName: "DATE",
      minWidth: EXTRA_EXTRA_LARGE_WIDTH,
      type: "dateTime",
      aggregable: true,
      valueGetter: ({ value }) => value && new Date(formatDateToLocal(value)),
      valueFormatter: (value, row, column, apiRef) => {
        return value
          ? formatDateToLocal(value) +
              " " +
              moment.tz(moment.tz.guess()).zoneAbbr()
          : EMPTY_FIELD;
      },
    },
    {
      field: "teams",
      headerName: "TEAMS",
      minWidth: EXTRA_EXTRA_LARGE_WIDTH,
      type: "string",
      editable: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleTeamClick(params.row.away_team)}
            >
              <Typography sx={{ textDecoration: "underline" }}>
                {`${
                  params.row.away_team_rank
                    ? "#" + params.row.away_team_rank
                    : ""
                } ${params.row.away_team}`}
              </Typography>
            </div>
            <Typography sx={{ paddingLeft: 1, paddingRight: 1 }}>@</Typography>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleTeamClick(params.row.home_team)}
            >
              <Typography sx={{ textDecoration: "underline" }}>
                {`${
                  params.row.home_team_rank
                    ? "#" + params.row.home_team_rank
                    : ""
                } ${params.row.home_team}`}
              </Typography>
            </div>
          </>
        );
      },
    },
    {
      field: "scout",
      headerName: "SCOUT",
      minWidth: EXTRA_EXTRA_LARGE_WIDTH,
      type: "string",
      aggregable: true,
      filterOperators: withStringOperators,
      valueFormatter: (value, row, column, apiRef) => {
        let scout = users?.find((u: User) => {
          return u.email === value;
        });

        return scout ? scout.name : value;
      },
    },
    {
      field: "tv_network",
      headerName: "TV NETWORK",
      minWidth: MEDIUM_WIDTH,
      type: "string",
      aggregable: true,
      filterOperators: withStringOperators,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: MEDIUM_WIDTH,
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={() => handleEditGame(params.row.id)}
            sx={{ color: theme.palette.primary.main }}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteGame(params.row.id)}
            sx={{ color: theme.palette.primary.main }}
          />,
        ];
      },
    },
  ];
};
