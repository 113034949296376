import {
    IconButton,
    Link,
    Stack,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from "@mui/icons-material/Close";
import { DATABASE_ROUTE } from "../routes";
import { Folder } from "../api/types";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "../nav/NavDrawer";
import { useListContext } from "../contexts/ListContext";
import { useLists } from "../api/queries";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function ToolbarLeft() {
  // HOOKS
  const theme = useTheme();
  const navigate = useNavigate();

  // CONTEXT
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { listInContext, setListInContext } = useListContext();

  // STATE
  const [drawerOpen, setDrawerOpen] = useState(false);

  // QUERIES
  const folders = useLists();

  const handleDrawerClick = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <NavDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        folders={folders.data as Folder[]}
      />

      {/* TEAM MENU
      {club === CHICAGO_FIRE && (
        <TeamMenu
          teamMenuOpen={teamMenuOpen}
          teamMenuAnchorEl={teamMenuAnchorEl}
          setTeamMenuAnchorEl={setTeamMenuAnchorEl}
        />
      )} */}

      <Stack direction="row" spacing={0}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerClick}
          edge="start"
        >
          {drawerOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>

        {/* {club === CHICAGO_FIRE && (
          <Tooltip title="Switch Team">
            <IconButton
              color="info"
              onClick={handleTeamMenuClick}
              size="small"
              aria-controls={open ? "team-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="button"
                color="inherit"
              >
                {icon}&nbsp;{!isScreenSmall && team.replace("_", " ")}
              </Typography>
            </IconButton>
          </Tooltip>
        )} */}

        {listInContext?.name && !isScreenSmall && (
          <>
            <Link
                noWrap={true}
                color={theme.palette.primary.contrastText}
                onClick={() => {setListInContext(undefined); navigate(DATABASE_ROUTE)}}
                sx={{
                  cursor: "pointer",
                  m: "auto",
                  ml: 1
                }}
                variant="button"
            >
                ALL PLAYERS
            </Link>

            <ChevronRightIcon 
                fontSize="small"
                sx={{
                    mb: "auto",
                    mt: "auto"
                }} 
            />

            <Link
                onClick={() => {navigate(`${DATABASE_ROUTE}?list_id=${listInContext.id}`)}}
                noWrap={true}
                color={theme.palette.primary.contrastText}
                sx={{
                  cursor: "pointer",
                  m: "auto"
                }}
                variant="button"
            >
                {listInContext.name}
            </Link>
          </>
        )}
      </Stack>
    </>
  );
}
