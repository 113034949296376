import {
  COACHES_CORNER_ROUTE,
  IDP_ROUTE,
} from "../routes";
import { Divider, Stack } from "@mui/material";

import { Folder } from "../api/types";
import NavFooter from "./NavFooter";
import ScoutingMenu from "./ScoutingMenu";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import TreeItemLink from "./TreeItemLink";
import { useOktaAuth } from "@okta/okta-react";
import { useUserContext } from "../contexts/UserContext";

export default function NavMenu(props: { folders: Folder[] | null }) {
  // HOOKS
  const { authState } = useOktaAuth();

  // CONTEXT
  const { homePagePath } = useUserContext();

  return (
    <>
      {authState?.isAuthenticated && (
        <Stack
          height="100%"
          direction="column"
          display="flex"
          justifyContent="space-between"
          sx={{ paddingLeft: 2, paddingRight: 2 }}
        >
          <SimpleTreeView>
            {/* HOME */}
            <>
                <TreeItemLink
                label="HOME"
                to={homePagePath}
                />

                <Divider />
            </>

            {/* COACHES CORNER */}
            <TreeItemLink
                label="COACHES CORNER"
                to={COACHES_CORNER_ROUTE}
            />

            {/* IDP */}
            <TreeItemLink
                label="IDP"
                to={IDP_ROUTE}
            />

            {/* EQUIPMENT */}
            {/* <TreeItemLink
                label="EQUIPMENT"
                to={EQUIPMENT_ROUTE}
            /> */}

            {/* PERFORMANCE */}
            {/* <TreeItemLink
                label="PERFORMANCE"
                to={PERFORMANCE_ROUTE}
            /> */}

            {/* SCOUTING */}
            <ScoutingMenu folders={props.folders} />
          </SimpleTreeView>

          <NavFooter />
        </Stack>
      )}
    </>
  );
}
