import {
  DATABASE_ROUTE,
  SCOUTING_REPORTS_ROUTE,
  SEARCH_ROUTE,
} from "../routes";
import {
  FIRST_TEAM,
  PLAYERS_PERMISSION,
  SUPERUSER,
} from "../constants";

import { Folder } from "../api/types";
import ListsMenu from "./ListsMenu";
import { TreeItem } from "./TreeItem";
import TreeItemLink from "./TreeItemLink";
import { useTeamContext } from "../contexts/TeamContext";
import { useUserContext } from "../contexts/UserContext";

export default function ScoutingMenu(props: { folders: Folder[] | null }) {

  //   CONTEXT
  const { roles, permissions } = useUserContext();
  const { team } = useTeamContext();

  return (
    <TreeItem itemId="scouting" label="SCOUTING">
      {(team === FIRST_TEAM) &&
        (roles.includes(SUPERUSER) ||
        permissions.includes(PLAYERS_PERMISSION)) && (
            <TreeItemLink
                to={DATABASE_ROUTE}
                label="DATABASE"
                nested
            />
      )}

      {/* <TreeItemLink
        to={SCOUTING_DEPTH_ROUTE}
        label="DEPTH CHART"
        nested
      /> */}

      <ListsMenu folders={props.folders} />

      <TreeItemLink
        to={SCOUTING_REPORTS_ROUTE}
        label="REPORTS"
        nested
      />

      <TreeItemLink
        to={SEARCH_ROUTE}
        label="SEARCH"
        nested
      />

      {/* <TreeItemLink
        to={SCOUTING_SCHEDULING}
        label="SCHEDULING"
        nested
      />

      <TreeItemLink
        to={SCOUTING_SHORTLIST_ROUTE}
        label="SHORT LIST"
        nested
      />

      <TreeItemLink
        to={SCOUTING_TEAM_OF_THE_WEEK_ROUTE}
        label="TEAM OF THE WEEK"
        nested
      /> */}
    </TreeItem>
  );
}
