import { Backdrop, Button, useMediaQuery } from "@mui/material";

import LoginIcon from "@mui/icons-material/Login";
import landingBackground from "../../assets/landing_background.jpg";
import { useOktaAuth } from "@okta/okta-react";
import { useThemeContext } from "../../contexts/CustomThemeContext";

interface SignInPageProps {
    triggerLogin: () => {}
}

export default function SignInPage(props: SignInPageProps) {
    const { theme } = useThemeContext();
    const { authState} = useOktaAuth();
    const isScreenMedium = useMediaQuery(theme.breakpoints.down("md"));
    // console.debug(isScreenMedium);

    return (
        <Backdrop
            sx={{
                display: "flex",
                justifyContent: isScreenMedium ? "center" : "space-evenly",
                alignItems: isScreenMedium ? "center" : "flex-end",
                backgroundColor: theme.palette.primary.main,
                backgroundImage: isScreenMedium ? `` : `url(${landingBackground})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                zIndex: (theme) => theme.zIndex.appBar + 1
            }}
            open={!authState?.isAuthenticated}
        >
            {!authState?.isAuthenticated && (
                <Button
                    size="large"
                    variant="contained"
                    endIcon={<LoginIcon />}
                    sx={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "auto",
                        marginBottom: isScreenMedium ? "auto" : "20vh",
                        backgroundColor: theme.palette.primary.contrastText,
                        color: theme.palette.primary.dark,
                    }}
                    onClick={() => {
                        props.triggerLogin();
                    }}
                >
                    Sign In
                </Button>
            )}
        </Backdrop>
    );
}
