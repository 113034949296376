import { Box } from "@mui/material";
import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";
import { useAuth } from "../api/queries";

export interface TableauDashboardParams {
    [key: string]: string
}

interface TableauDashboardProps {
    url: string
    params?: TableauDashboardParams
}

export default function TableauDashboard(props: TableauDashboardProps) {
    // CONTEXT
    const auth = useAuth();
    const marginTop = 10;
    const padding = 12;

    return (
        auth.data?.tableau_jwt ?
            <Box
                id="tableau"
                key="tableauBox"
                overflow="auto"
                mt={marginTop}
                pl={padding}
                pr={padding}
                sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <TableauEmbed
                    key="tableauEmbed"
                    version="3.5.0"
                    sourceUrl={props.url}
                    // width={width}
                    token={auth.data?.tableau_jwt}
                >
                    { props.params && Object.keys(props.params).map((key : string) => {
                        // Capitalize first letter to match parameter casing
                        let value = props.params ? `${props.params[key]}` : '';
                        key = key.charAt(0).toUpperCase() + key.slice(1);
                        // console.debug(`${key}: ${value}`);

                        return (
                            <custom-parameter name={key} value={value} />
                        )
                    })}
                </TableauEmbed>       
            </Box>
        : <></>
    );
}
