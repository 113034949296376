import { List, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography, styled } from "@mui/material"

import CloudSyncRoundedIcon from "@mui/icons-material/CloudSyncRounded";
import { ExposuresDialog } from "./ExposuresDialog";
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import packageJson from "../../package.json";
import { useCacheBuster } from "react-cache-buster";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";
import { useState } from "react";
import { useTeamContext } from "../contexts/TeamContext";

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderRadius: "6px",
    }
}));

const NavFooter = () => {
    const { checkCacheStatus } = useCacheBuster();
    const { name } = useOktaAuthContext();
    const { icon } = useTeamContext();

    const [exposuresDialogOpen, setExposuresDialogOpen] =
      useState<boolean>(false);

    return (
        <>
          <ExposuresDialog exposuresDialogOpen={exposuresDialogOpen} setExposuresDialogOpen={setExposuresDialogOpen} />

          <List dense>
            <StyledListItemButton>
              <ListItemIcon>
                {icon}
              </ListItemIcon>

              <Typography>
                {name}
              </Typography>
            </StyledListItemButton>
            
            <StyledListItemButton onClick={checkCacheStatus}>
              <Tooltip title="Check for new version">
                  <ListItemIcon>
                    <SystemUpdateIcon fontSize="small" />
                  </ListItemIcon>
                </Tooltip>
              <ListItemText>Version: {packageJson.version}</ListItemText>
            </StyledListItemButton>

            <StyledListItemButton onClick={() => setExposuresDialogOpen(!exposuresDialogOpen)}>
              <ListItemIcon>
                <CloudSyncRoundedIcon fontSize="small" />
              </ListItemIcon>

              <ListItemText>View Data Freshness</ListItemText>
            </StyledListItemButton>
          </List>
        </>
    )
}

export default NavFooter;
