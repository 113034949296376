import {
  ACADEMY_AGE_GROUPINGS,
  ACADEMY_TEAM,
  SCOUTING_POSITIONS,
  SCOUTING_REPORT_GROUPINGS,
  SCOUTING_REPORT_GROUP_COLLEGE,
} from "../../../constants";
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip as MuiTooltip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { GetRatingLabel, GetRatings } from "../utils";
import { SEVERITY_INFO, useAlertContext } from "../../../contexts/AlertContext";
import { ScoutedPlayer, ScoutingReport } from "../../../api/types";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import {
  useCreateScoutingReportMutation,
  useUpdateScoutingReportMutation,
} from "../../../api/mutations";

import AddScoutedPlayerDialog from "./AddScoutedPlayerDialog";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import CloseButton from "../../../components/CloseButton";
import DefaultDatePicker from "../../../components/DefaultDatePicker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { useClubContext } from "../../../contexts/ClubContext";
import { useConstantsContext } from "../../../contexts/ConstantsContext";
import { useDebouncedCallback } from "use-debounce";
import { useGroupContext } from "../../../contexts/GroupContext";
import { useOktaAuthContext } from "../../../contexts/OktaAuthContext";
import { useScoutedPlayerSearch } from "../../../api/queries";
import { useTeamContext } from "../../../contexts/TeamContext";

export interface MutateScoutingReportDialogProps {
  scoutedPlayer: ScoutedPlayer | undefined;
  open: boolean;
  report: ScoutingReport | undefined;
  setReportInContext: Dispatch<SetStateAction<ScoutingReport | undefined>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

export default function MutateScoutingReportDialog(
  props: MutateScoutingReportDialogProps
) {
  // CONTEXT
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { name } = useOktaAuthContext();
  const clubContext = useClubContext();
  const { team } = useTeamContext();
  const { group } = useGroupContext();
  const { schools } = useConstantsContext();
  const { setAlertOptions } = useAlertContext();

  // STATE
  const [searchForScoutedPlayer, setSearchForScoutedPlayer] =
    useState<boolean>(true);

  const [affiliation, setAffiliation] = useState<string>("");
  const [additionalInfoExpanded, setAdditionalInfoExpanded] =
    useState<boolean>(false);
  const [notesExpanded, setNotesExpanded] = useState<boolean>(false);
  const [birthYear, setBirthYear] = useState<string>("");
  const [club, setClub] = useState<string>("");
  const [contractInfo, setContactInfo] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>();
  const [domestic, setDomestic] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>("");
  const [foot, setFoot] = useState<string>("");
  const [grouping, setGrouping] = useState<string>("");
  const [kitNumber, setKitNumber] = useState<number | string>("");
  const [lastName, setLastName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [opponent, setOpponent] = useState<string>("");
  const [opponentResistance, setOpponentResistance] = useState<number | string>(
    ""
  );
  const [otherContactInfo, setOtherContactInfo] = useState<string>("");
  const [position, setPosition] = useState<string>("");
  const [rating, setRating] = useState<number>(-1);
  const [projectedRating, setProjectedRating] = useState<number>(-1);
  const [notes, setNotes] = useState<string>("");
  const [scoutedPlayerId, setScoutedPlayerId] = useState<number | undefined>(
    props.scoutedPlayer?.id
  );
  const [scoutedPlayerName, setScoutedPlayerName] = useState<
    string | undefined
  >(props.scoutedPlayer?.name || "");
  const [secondaryPosition, setSecondaryPosition] = useState<string>("");
  const [separator, setSeparator] = useState<string>("");
  const [yearInSchool, setYearInSchool] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setScout] = useState<string>("");
  const [date, setDate] = useState<Date | undefined>();
  const [ratings, setRatings] = useState<string[]>([]);
  const [comparison, setComparison] = useState<string>("");
  const [strengths, setStrengths] = useState<string>("");
  const [areasToImprove, setAreasToImprove] = useState<string>("");

  const [openAddPlayerDialog, setOpenAddPlayerDialog] =
    useState<boolean>(false);

  const groups =
    team === ACADEMY_TEAM ? ACADEMY_AGE_GROUPINGS : SCOUTING_REPORT_GROUPINGS;

  // QUERIES
  const searchScoutedPlayerQuery = useScoutedPlayerSearch(
    {
      query: scoutedPlayerName || "",
      by: "name",
    },
    3,
    1
  );

  // MUTATIONS
  const createScoutingReport = useCreateScoutingReportMutation();
  const updateScoutingReport = useUpdateScoutingReportMutation(
    props.setReportInContext
  );

  // REFS
  const formRef = useRef();

  // FUNCTIONS
  const handleSubmit = () => {
    let payload = {
      affiliation: affiliation,
      birth_year: birthYear,
      club: club,
      contact_info: contractInfo,
      date_of_birth: dateOfBirth,
      domestic: domestic,
      first_name: firstName,
      foot: foot,
      for_club: clubContext.club,
      grouping: grouping,
      kit_number: kitNumber,
      last_name: lastName,
      location: location,
      notes: notes,
      opponent: opponent,
      opponent_resistance: opponentResistance,
      other_contact_info: otherContactInfo,
      scouted_player_id: scoutedPlayerId,
      position: position,
      projected_rating: projectedRating,
      rating: rating,
      scout: name,
      secondary_position: secondaryPosition,
      separator: separator,
      team: team,
      year_in_school: yearInSchool,
      comparison: comparison,
      strengths: strengths,
      areas_to_improve: areasToImprove,
    };

    console.log(payload);

    if (!props?.report?.id) {
      const obj = Object.fromEntries(
        Object.entries({
          ...payload,
          date: new Date(),
        }).filter(([_, v]) => v !== null && v !== "")
      );
      console.debug(obj);
      createScoutingReport.mutate(obj as ScoutingReport);
    } else {
      const obj = Object.fromEntries(
        Object.entries({
          ...payload,
          id: props.report && props.report.id,
          date: date,
        }).filter(([_, v]) => v !== null && v !== "")
      );
      console.debug(obj);
      updateScoutingReport.mutate(obj as ScoutingReport);
    }

    props.setOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setScoutedPlayerId(undefined);
    setScoutedPlayerName("");
    setSearchForScoutedPlayer(true);
    setAdditionalInfoExpanded(false);
    setAffiliation("");
    setBirthYear("");
    setClub("");
    setContactInfo("");
    setDateOfBirth(undefined);
    setFirstName("");
    setFoot("");
    setKitNumber("");
    setLastName("");
    setLocation("");
    setOpponent("");
    setOpponentResistance("");
    setOtherContactInfo("");
    setPosition("");
    setRating(-1);
    setProjectedRating(-1);
    setNotes("");
    setSecondaryPosition("");
    setSeparator("");
    setYearInSchool("");
    setComparison("");
    setStrengths("");
    setAreasToImprove("");
  };

  const onChangeScoutedPlayerName = useDebouncedCallback(
    (newValue: string) => {
      setScoutedPlayerId(undefined);
      setScoutedPlayerName(newValue);
    }, 400);


  const onScoutedPlayerSearchResultClick = useCallback(
    (scouted_player: ScoutedPlayer) => {
      // console.debug(player.id);
      // console.debug(player);
      // setClub(scouted_player.team);
      // setDate(undefined);
      // setBirthYear(
      //   moment(scouted_player.birthdate, "YYYY-MM-DD").year().toString()
      // );
      // setDateOfBirth(moment(scouted_player.birthdate).toDate());
      // setFirstName(scouted_player.first_name);
      // setFoot(scouted_player.foot);
      // setLastName(scouted_player.last_name);
      setScoutedPlayerId(scouted_player.id);
      setScoutedPlayerName(scouted_player.name || "");
    },
    [setScoutedPlayerId]
  );

  // EFFECTS
  useEffect(() => {
    // Set sane defaults
    props.report?.affiliation
      ? setAffiliation(props.report.affiliation)
      : setAffiliation("");
    props.report?.birth_year
      ? setBirthYear(props.report.birth_year)
      : setBirthYear("");
    props.report?.club ? setClub(props.report.club) : setClub("");
    props.report?.contact_info
      ? setContactInfo(props.report.contact_info)
      : setContactInfo("");
    props.report?.date_of_birth
      ? setDateOfBirth(props.report.date_of_birth)
      : setDateOfBirth(undefined);
    props.report?.domestic
      ? setDomestic(props.report.domestic)
      : setDomestic(true);
    props.report?.first_name
      ? setFirstName(props.report.first_name)
      : setFirstName("");
    props.report?.foot ? setFoot(props.report.foot) : setFoot("");
    props.report?.kit_number
      ? setKitNumber(props.report.kit_number)
      : setKitNumber("");
    props.report?.last_name
      ? setLastName(props.report.last_name)
      : setLastName("");
    props.report?.opponent
      ? setOpponent(props.report.opponent)
      : setOpponent("");
    props.report?.opponent_resistance
      ? setOpponentResistance(props.report.opponent_resistance)
      : setOpponentResistance("");
    props.report?.projected_rating
      ? setRating(Math.floor(props?.report?.projected_rating))
      : setProjectedRating(-1);
    props.report?.rating
      ? setRating(Math.floor(props?.report?.rating))
      : setRating(-1);
    props.report?.other_contact_info
      ? setOtherContactInfo(props.report.contact_info)
      : setOtherContactInfo("");
    props.report?.scouted_player_id
      ? setSearchForScoutedPlayer(props.report.scouted_player_id ? false : true)
      : setSearchForScoutedPlayer(true);
    props.report?.scouted_player_id
      ? setScoutedPlayerId(props.report.scouted_player_id)
      : setScoutedPlayerId(undefined);
    props.report?.position
      ? setPosition(props.report.position)
      : setPosition("");
    props.report?.secondary_position
      ? setSecondaryPosition(props.report.secondary_position)
      : setSecondaryPosition("");
    props.report?.year_in_school
      ? setYearInSchool(props.report.year_in_school)
      : setYearInSchool("");
    props.report?.scout ? setScout(props.report.scout) : setScout("");
    props.report?.date ? setDate(props.report.date) : setDate(undefined);

    if (props.report?.notes) {
      setNotes(props.report.notes);
    } else {
      setNotes("");
    }
  }, [props.report]);

  useEffect(() => {
    setRatings(GetRatings(team, grouping));
    setRating(-1);
    setProjectedRating(-1);
  }, [grouping, team]);

  useEffect(() => {
    setGrouping(group);
  }, [group]);

  useEffect(() => {
    setScoutedPlayerId(props.scoutedPlayer?.id);
    setScoutedPlayerName(props.scoutedPlayer?.name);
  }, [props.scoutedPlayer]);

  const currentYear = moment().year();
  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  const birthYears = range(currentYear, currentYear - 40, -1);

  return (
    <Box key={"mutate-scouting-report-dialog-outer-box"}>
      <AddScoutedPlayerDialog
        open={openAddPlayerDialog}
        setOpen={setOpenAddPlayerDialog}
        closeDialog={() => {
          setOpenAddPlayerDialog(false);
        }}
        grouping={grouping}
      />

      <Dialog
        open={props.open}
        onClose={(event: object, reason: string) => {
          if (reason === "escapeKeyDown") {
            props.onClose();
            resetForm();
          }
        }}
        key={"Scouting Report"}
        fullScreen={fullScreen}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: fullScreen ? 0 : 5,
            width: "100%",
          },
        }}
      >
        <DialogTitle
          key={"mutate-scouting-report-dialog-title"}
          sx={{ textAlign: "center" }}
        >
          SCOUTING REPORT
        </DialogTitle>

        <ValidatorForm
          ref={formRef.current}
          onSubmit={() => name && handleSubmit()}
          // onError={errors => console.debug(errors)}
        >
          <DialogContent key={"mutate-scouting-report-dialog-content"}>
            <CloseButton
              position="absolute"
              top={5}
              left={5}
              onClick={() => {
                props.onClose();
                resetForm();
              }}
            />

            <Stack key={"mutate-scouting-report-dialog-stack"} spacing={2}>
              <Grid container>
                <Grid xs={11} item>
                  <SelectValidator
                    errorMessages={["this field is required"]}
                    fullWidth
                    id="report-dialog-group-select"
                    label="Group"
                    name="group"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setGrouping(event.target.value);
                    }}
                    value={grouping}
                    variant="outlined"
                    validators={["required"]}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <MenuItem
                      key="group-empty"
                      value=""
                      sx={{ width: "100%" }}
                    />

                    {groups.map((grp, i) => {
                      return (
                        <MenuItem
                          key={`group-${grp}-${i}`}
                          value={grp}
                          sx={{ width: "100%" }}
                        >
                          {grp}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>
                </Grid>

                <Grid xs={1} item>
                  <MuiTooltip title="Clear">
                    <IconButton
                      aria-label="cancel"
                      size="large"
                      onClick={() => {
                        setGrouping("");
                        resetForm();
                      }}
                    >
                      <ClearIcon fontSize="inherit" />
                    </IconButton>
                  </MuiTooltip>
                </Grid>
              </Grid>

              {grouping === SCOUTING_REPORT_GROUP_COLLEGE && (
                <FormControlLabel
                  label="Domestic"
                  control={
                    <Checkbox
                      checked={domestic}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => setDomestic(checked)}
                    />
                  }
                />
              )}

              {grouping && searchForScoutedPlayer && (
                <Grid container>
                  <Grid xs={11} item>
                    <TextValidator
                      errorMessages={["this field is required"]}
                      id="report-dialog-scouted-player-name-select"
                      label="Scouted Player Name"
                      name="scouted_player_name"
                      validators={["required"]}
                      value={scoutedPlayerName}
                      variant="outlined"
                      fullWidth
                      onChange={(e: any) => onChangeScoutedPlayerName(e.target.value)}
                    />
                  </Grid>

                  <Grid xs={1} item>
                    <MuiTooltip title="Clear">
                      <IconButton
                        aria-label="cancel"
                        size="large"
                        onClick={() => {
                          resetForm();
                        }}
                      >
                        <ClearIcon fontSize="inherit" />
                      </IconButton>
                    </MuiTooltip>
                  </Grid>
                </Grid>
              )}

              {(searchScoutedPlayerQuery.isFetching ||
                searchScoutedPlayerQuery.isLoading) && (
                <LinearProgress color="secondary" />
              )}

              {grouping &&
                searchScoutedPlayerQuery.isFetched &&
                searchForScoutedPlayer && (
                  <MenuList disablePadding dense sx={{ pr: 6 }}>
                    {searchScoutedPlayerQuery.data?.data.map(
                      (scoutedPlayer: ScoutedPlayer, i: number) => {
                        if (scoutedPlayer?.id) {
                          return (
                            <MenuItem
                              key={i}
                              dense
                              onClick={() => {
                                onScoutedPlayerSearchResultClick(scoutedPlayer);
                              }}
                              selected={scoutedPlayerId === scoutedPlayer.id}
                              sx={{ borderRadius: 2 }}
                            >
                              <ListItemAvatar>
                                <Avatar src={scoutedPlayer.image} />
                              </ListItemAvatar>

                              <ListItemText
                                primary={
                                  <Stack
                                    direction="row"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Link
                                      noWrap={true}
                                      onClick={() => {
                                        if (scoutedPlayer.tm_link) {
                                          window.open(
                                            scoutedPlayer.tm_link,
                                            "_blank",
                                            "noopener,noreferrer"
                                          );
                                        } else {
                                          setAlertOptions({
                                            message: `${
                                              scoutedPlayer.name
                                                ? scoutedPlayer.name
                                                : "this player"
                                            } doesn't have a transfermakrt profile`,
                                            severity: SEVERITY_INFO,
                                          });
                                        }
                                      }}
                                    >
                                      {scoutedPlayer.name}
                                    </Link>

                                    {scoutedPlayer.age}
                                  </Stack>
                                }
                                secondary={
                                  <Stack
                                    direction="row"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Typography variant="caption">
                                      {scoutedPlayer.team}
                                    </Typography>
                                    <Typography variant="caption">
                                      {scoutedPlayer.nationality}
                                    </Typography>
                                  </Stack>
                                }
                              />
                            </MenuItem>
                          );
                        }

                        return null;
                      }
                    )}

                    {scoutedPlayerId === undefined &&
                      searchForScoutedPlayer && (
                        <MenuItem
                          dense
                          onClick={() => {
                            setOpenAddPlayerDialog(true);
                            props.setOpen(false);
                            resetForm();
                          }}
                        >
                          <ListItemAvatar sx={{ ml: 1 }}>
                            <PersonAddAlt1Icon />
                          </ListItemAvatar>
                          <ListItemText primary="Add Player" />
                        </MenuItem>
                      )}
                    <Divider />
                  </MenuList>
                )}

              {!searchForScoutedPlayer && (
                <>
                  <TextValidator
                    id="report-dialog-first-name-select"
                    label="Player First Name"
                    name="first_name"
                    value={firstName}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                    variant="outlined"
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFirstName(event.target.value)
                    }
                  />

                  <TextValidator
                    id="report-dialog-last-name-select"
                    label="Player Last Name"
                    name="last_name"
                    value={lastName}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                    variant="outlined"
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setLastName(event.target.value)
                    }
                  />

                  <TextValidator
                    select={grouping === SCOUTING_REPORT_GROUP_COLLEGE}
                    id="report-dialog-club-select"
                    label={
                      grouping === SCOUTING_REPORT_GROUP_COLLEGE
                        ? "College Team"
                        : "Club/Team"
                    }
                    name="location"
                    value={club}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                    variant="outlined"
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setClub(event.target.value)
                    }
                  >
                    {schools?.map((school) => {
                      return (
                        <MenuItem key={school} value={school}>
                          {school}
                        </MenuItem>
                      );
                    })}
                  </TextValidator>
                </>
              )}

              {!searchForScoutedPlayer &&
                grouping === SCOUTING_REPORT_GROUP_COLLEGE && (
                  <TextValidator
                    id="report-dialog-year-in-school-select"
                    label={"Year in School"}
                    name="year_in_school"
                    value={yearInSchool}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                    variant="outlined"
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setYearInSchool(event.target.value)
                    }
                  />
                )}

              {!searchForScoutedPlayer && (
                <>
                  <TextValidator
                    select
                    id="report-dialog-birth-year-select"
                    label="Birth Year"
                    name="birth_year"
                    value={birthYear}
                    validators={
                      grouping === SCOUTING_REPORT_GROUP_COLLEGE
                        ? []
                        : ["required"]
                    }
                    errorMessages={["this field is required"]}
                    variant="outlined"
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setBirthYear(event.target.value)
                    }
                  >
                    {birthYears.map((birthYear, i) => {
                      return (
                        <MenuItem
                          key={`birth-year-${birthYear}`}
                          value={birthYear}
                        >
                          {birthYear}
                        </MenuItem>
                      );
                    })}
                  </TextValidator>

                  <SelectValidator
                    errorMessages={["this field is required"]}
                    fullWidth
                    id="report-dialog-position-textfield"
                    label="Position"
                    name="position"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setPosition(event.target.value)
                    }
                    value={position}
                    validators={["required"]}
                    variant="outlined"
                  >
                    <MenuItem
                      key="position-empty"
                      value=""
                      sx={{ width: "100%" }}
                    />

                    {SCOUTING_POSITIONS.map((position, i) => {
                      return (
                        <MenuItem key={position} value={i + 1}>
                          {position}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>
                </>
              )}

              {!searchForScoutedPlayer &&
                grouping !== SCOUTING_REPORT_GROUP_COLLEGE && (
                  <>
                    <SelectValidator
                      id="report-dialog-secondary-position-textfield"
                      label="Secondary Position"
                      name="secondary_position"
                      value={secondaryPosition}
                      variant="outlined"
                      fullWidth
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setSecondaryPosition(event.target.value)
                      }
                    >
                      <MenuItem
                        key="secondary-position-empyt"
                        value=""
                        sx={{ width: "100%" }}
                      />

                      {SCOUTING_POSITIONS.map((position, i) => {
                        return (
                          <MenuItem key={position} value={i + 1}>
                            {position}
                          </MenuItem>
                        );
                      })}
                    </SelectValidator>

                    <TextValidator
                      id="report-dialog-affiliation-select"
                      label={"Affiliation"}
                      name="affiliation"
                      value={affiliation}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                      variant="outlined"
                      fullWidth
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setAffiliation(event.target.value)
                      }
                    />
                  </>
                )}
              {grouping && !searchForScoutedPlayer && !scoutedPlayerId && (
                <>
                  <TextValidator
                    id="report-dialog-attributes-comparison"
                    label={"Comparison"}
                    name="comparison"
                    value={comparison}
                    validators={[]}
                    errorMessages={[]}
                    variant="outlined"
                    fullWidth
                    multiline
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setComparison(event.target.value)
                    }
                  />
                  <TextValidator
                    id="report-dialog-attributes-strengths"
                    label={"Strengths"}
                    name="Strengths"
                    value={strengths}
                    validators={[]}
                    errorMessages={[]}
                    variant="outlined"
                    fullWidth
                    multiline
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setStrengths(event.target.value)
                    }
                  />
                  <TextValidator
                    id="report-dialog-attributes-areas-to-improve"
                    label={"Areas to Improve"}
                    name="areas-to-improve"
                    value={areasToImprove}
                    validators={[]}
                    errorMessages={[]}
                    variant="outlined"
                    fullWidth
                    multiline
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setAreasToImprove(event.target.value)
                    }
                  />
                </>
              )}

              {grouping && (scoutedPlayerId || !searchForScoutedPlayer) && (
                <Grid container>
                  <Grid xs={11} item>
                    <SelectValidator
                      id="report-dialog-rating-select"
                      errorMessages={["this field is required"]}
                      fullWidth
                      label="Rating"
                      name="rating"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setRating(event.target.value as unknown as number)
                      }
                      sx={{
                        width: "100%",
                        minWidth: "100%",
                      }}
                      value={rating}
                      validators={team === ACADEMY_TEAM ? ["required"] : []}
                      variant="outlined"
                    >
                      <MenuItem
                        key="rating-empty"
                        value={-1}
                        sx={{ width: "100%" }}
                      />
                      {ratings.map((rating: string, i: number) => {
                        return (
                          <MenuItem key={rating} value={i}>
                            {GetRatingLabel(team, i, grouping)}
                          </MenuItem>
                        );
                      })}
                    </SelectValidator>
                  </Grid>
                </Grid>
              )}

              {grouping && (scoutedPlayerId || !searchForScoutedPlayer) && (
                <Grid container>
                  <Grid xs={11} item>
                    <SelectValidator
                      id="report-dialog-projectd-rating-select"
                      fullWidth
                      label="Projected Rating"
                      name="projected-rating"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setProjectedRating(
                          event.target.value as unknown as number
                        )
                      }
                      sx={{
                        width: "100%",
                        minWidth: "100%",
                      }}
                      value={projectedRating}
                      variant="outlined"
                    >
                      <MenuItem
                        key="projected-rating-empty"
                        value={-1}
                        sx={{ width: "100%" }}
                      />
                      {ratings.map((rating: string, i: number) => {
                        return (
                          <MenuItem key={`projected-${rating}`} value={i}>
                            {GetRatingLabel(team, i, grouping)}
                          </MenuItem>
                        );
                      })}
                    </SelectValidator>
                  </Grid>

                  <Grid xs={1} item>
                    <MuiTooltip title="Clear">
                      <IconButton
                        aria-label="cancel"
                        size="large"
                        onClick={() => setRating(-1)}
                      >
                        <ClearIcon fontSize="inherit" />
                      </IconButton>
                    </MuiTooltip>
                  </Grid>
                </Grid>
              )}

              {grouping && (
                <>
                  <Divider
                    onClick={() =>
                      setAdditionalInfoExpanded(!additionalInfoExpanded)
                    }
                  >
                    <Chip
                      color="primary"
                      label="ADDITIONAL INFO"
                      sx={{ cursor: "pointer", marginTop: 2 }}
                    />
                  </Divider>

                  <Collapse
                    key={"additional-info-collapse"}
                    in={additionalInfoExpanded}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Stack spacing={2}>
                      {!searchForScoutedPlayer && (
                        <>
                          <DefaultDatePicker
                            key={
                              "mutate-scouting-report-dialog-stack-dob-picker"
                            }
                            label="Date of Birth"
                            value={dateOfBirth}
                            onChange={(
                              value: Date | null,
                              context: PickerChangeHandlerContext<DateValidationError>
                            ) => value && setDateOfBirth(value)}
                          />

                          <TextValidator
                            id="report-dialog-location-select"
                            label="Location"
                            name="location"
                            value={location}
                            variant="outlined"
                            fullWidth
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setLocation(event.target.value)}
                          />

                          <TextField
                            key={"report-dialog-foot-textfield"}
                            margin="dense"
                            id="value"
                            label={"Foot"}
                            type="text"
                            variant="outlined"
                            rows={1}
                            value={foot}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setFoot(event.target.value)}
                            fullWidth
                          />

                          <TextField
                            key={"report-dialog-separator-textfield"}
                            margin="dense"
                            id="value"
                            label={"Separator"}
                            type="text"
                            variant="outlined"
                            rows={1}
                            value={separator}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setSeparator(event.target.value)}
                            fullWidth
                          />

                          <SelectValidator
                            id="report-dialog-kit-number-select"
                            label="Kit Number"
                            name="kit_number"
                            value={kitNumber}
                            variant="outlined"
                            fullWidth
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              setKitNumber(
                                event.target.value as unknown as number
                              )
                            }
                          >
                            <MenuItem
                              key="kit-number-empty"
                              value={-1}
                              sx={{ width: "100%" }}
                            />

                            {[...Array(100)].map((_, i) => {
                              return (
                                <MenuItem key={`kit-number-${i}`} value={i}>
                                  {i}
                                </MenuItem>
                              );
                            })}
                          </SelectValidator>
                        </>
                      )}

                      <TextValidator
                        id="value"
                        label="Position"
                        name="position"
                        type="text"
                        variant="outlined"
                        rows={1}
                        value={position}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setPosition(event.target.value)}
                        fullWidth
                      />

                      <TextValidator
                        id="value"
                        label="Opponent"
                        name="opponent"
                        type="text"
                        variant="outlined"
                        rows={1}
                        value={opponent}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setOpponent(event.target.value)}
                        fullWidth
                      />

                      <TextValidator
                        select
                        id="report-dialog-opponent-resistance-select"
                        label="Opponent Resistance"
                        name="opponent_resistance"
                        value={opponentResistance}
                        variant="outlined"
                        fullWidth
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setOpponentResistance(
                            event.target.value as unknown as number
                          )
                        }
                      >
                        {[...Array(10)].map((_, i) => {
                          return (
                            <MenuItem key={i} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          );
                        })}
                      </TextValidator>

                      {/* <TextField
                        key={"report-dialog-coach-info-textfield"}
                        margin="dense"
                        id="value"
                        label={"Contact Info"}
                        type="text"
                        variant="outlined"
                        rows={1}
                        value={contractInfo}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setContactInfo(event.target.value)}
                        fullWidth
                      />

                      <TextField
                        key={"report-dialog-other-contact-info-textfield"}
                        margin="dense"
                        id="value"
                        label={"Other Contact Info"}
                        type="text"
                        variant="outlined"
                        rows={1}
                        value={otherContactInfo}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setOtherContactInfo(event.target.value)}
                        fullWidth
                      /> */}
                    </Stack>
                  </Collapse>
                </>
              )}
              {grouping && (
                <>
                  <Divider onClick={() => setNotesExpanded(!notesExpanded)}>
                    <Chip
                      color="primary"
                      label="NOTES"
                      sx={{ cursor: "pointer" }}
                    />
                  </Divider>

                  <Collapse
                    key={"notes-info-collapse"}
                    in={notesExpanded}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Stack spacing={2}>
                      <TextField
                        key={"report-dialog-notes-textfield"}
                        margin="dense"
                        id="value"
                        label={"Notes"}
                        type="text"
                        variant="outlined"
                        multiline
                        rows={10}
                        value={notes}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setNotes(event.target.value)}
                        fullWidth
                      />
                    </Stack>
                  </Collapse>
                </>
              )}
            </Stack>
          </DialogContent>

          <DialogActions
            key={"mutate-scouting-report-dialog-actions"}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="outlined"
              type="submit"
              key="mutate-scouting-report-dialog-button"
            >
              SUBMIT
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </Box>
  );
}
